* {
  margin: 0;
  padding: 0;
}

h1 {
  color: #001864 !important;
}

h2 {
  color: #001864 !important;
}

h3 {
  color: #001864 !important;
}

body {
  color: #001864 !important;

}

.footer {
  position: flex;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff !important;
}

.footerleft {
  display: inline-block;
  *display: inline;
  vertical-align: top;
  width: 16.6%;
  height: auto;
  background-color: #ffffff !important;
}
.footerbottomleft {
  display: inline-block;
  *display: inline;
  vertical-align: top;
  width: 16.6%;
  height: auto;
  background-color: #ffffff !important;
}

.footerdivider {
  display: inline-block;
  *display: inline;
  vertical-align: top;
  width: 8.3%;
  height: 100px;
  background-color: #ffffff !important;
}

@media screen and (max-width: 800px) {
  .footerleft {
    display: inline-block;
    *display: inline;
    vertical-align: top;
    width: 100%;
    height: 160px;
    background-color: #ffffff !important;
  }
  .footerdivider {
    display: inline-block;
    *display: inline;
    vertical-align: top;
    width: 0%;
    background-color: #ffffff !important;
  }
  .footerbottomleft {
    display: inline-block;
    *display: inline;
    vertical-align: top;
    width: 100%;
    height: 30px;
    background-color: #ffffff !important;
  }
}

.grid-container {
  display: grid;
  justify-content: center;
  align-content: center;
  grid-auto-flow: column;
  grid-template-areas: "a a a";
}

.flex-container {
  display: grid;
  justify-content: center;
  align-content: center;
  grid-auto-flow: dense;
  padding-top: 10vh;
  text-align: center;
  padding-left: 50vw;
  max-width: 50%;
  font-family: Montserrat, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  color: #001864;
  }

  #cardContent {
  width: 400px;
  padding: 30px;
  border: 1px solid;
  border-radius: 4px;
  margin: 0 auto ;
  background-color: #F1EFF2;
  box-shadow: 0 1px 4px #000000;
}

  #cardContentGuides {
  width: 600px;
  padding: 30px;
  padding-bottom: 50px;
  border: 1px solid;
  border-radius: 4px;
  margin: 0 auto ;
  background-color: #F1EFF2;
  box-shadow: 0 1px 4px #000000;
}

#bckgDiv {
background: linear-gradient(to bottom, #001864 0%, #F0F2F5 100%);
}
#bckgDivGuides {
background: linear-gradient(to bottom, #001864 0%, #F0F2F5 100%);
margin-bottom: 1000px;
}
.titleText {
  font-size: 2.5em;
  font-weight: bold;
  color: #001864;
}
.helpContainers {
  font-family: "Montserrat";
  max-width: 80%;
  border: 1px solid;
  border-radius: 4px;
  padding: 30px;
  margin: 0 auto ;
   margin-top: 40px;
   background-color: #F1EFF2;
   box-shadow: 0 1px 4px #000000;
    }

    @media screen and (max-width: 540px) {
  .helpContainers {
    padding-left: 0vw;
    text-align: center;
    max-width: 90vw;
     }
}


div.hide {
  padding-top: 1px;
}
div.hidemin {
  padding-top: 1px;
}
@media screen and (max-width: 600px) {
  div.hide {
    display: none;
    padding-top: 10vh;
    justify-content: center;
    align-content: center;
    grid-auto-flow: dense;
  }
}
@media only screen and (min-width: 601px) {
  div.hidemin {
    display: none;
    padding-top: 10vh;
    justify-content: center;
    align-content: center;
    grid-auto-flow: dense;
  }
}
